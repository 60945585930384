<template>
<div class="content">
  <h3>Currently Equipped</h3>
  <ul>
    <li v-for="item in equipped" :key="item.id">{{ item.name }}</li>
  </ul>
  <h3>Available Equipment</h3>
  <ItemList :items="items"/>
</div>
</template>

<script>
import ItemList from "../components/ItemList.vue"
export default {
  name: 'Inventory',
  components: {
    ItemList
  },
  computed: {
    items() {
      return this.$root.$data.items;
    },
    equipped() {
      return this.$root.$data.items.filter(item => this.$root.$data.equipped.includes(item.id));
    }
  },
}
</script>

<style scoped>
h3 {
  padding-bottom: 5px;
  border-bottom: 2px solid black;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
ul, li {
  margin: 0;
  padding: 0;
}
</style>
