<template>
  <div class="item-list-content">
    <div class="items">
      <div class="item" v-for="item in items" :key="item.id">
        <div class="item-title">
          <h4 class="item-name">{{item.name}}</h4>
          <div class="image">
            <img :src="'/images/' + item.type +'.png'">
          </div>
        </div>
        <div class="item-info">
          <p class="item-info-head">Information</p>
          <div class="item-stats">
            <p>Type: {{item.type}}</p>
            <p>Defense: {{item.defense}}</p>
            <p>Rarity: {{item.rarity}}</p>
          </div>
        </div>
        <div class="buttons">
          <div :id='"equip" + item.id' :style="item.equipped == true ? 'display: none;' : 'display: block;'">
            <button class="equip-button" v-on:click="equipItem(item.id)">EQUIP</button>
          </div>
          <div :id='"unequip" + item.id' :style="item.equipped == true ? 'display: block;' : 'display: none;'">
            <button class="equip-button" v-on:click="unequipItem(item.id)">UN-EQUIP</button>
          </div>
          <button class="delete-button" v-on:click="deleteItem(item.id)">Delete</button>
          <router-link :to="'/item/' + item.id">Edit</router-link>
        </div>
      </div>
      <div class="addItem">
        <fieldset id="fieldset" class="fieldset">
          <legend>Add New Item:</legend>
          <label for="itemName">Item Name</label>
          <input type="text" id="itemName">
          <label for="itemType">Type</label>
          <select id="itemType">
            <option value="Head">Head</option>
            <option value="Chest">Chest</option>
            <option value="Leg">Leg</option>
            <option value="Misc">Misc</option>
          </select>
          <label for="itemDefense">Defense Stat</label>
          <select id="itemDefense">
            <option v-for="num in 30" :value="num" :key="num">{{num}}</option>
          </select>
          <label for="itemRarity">Item Rarity</label>
          <select id="itemRarity">
            <option v-for="num in 10" :value="num" :key="num">{{num}}</option>
          </select>
          <button class="auto" v-on:click="addItem()">Add Item</button>
        </fieldset>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemList',
  props: {
    items: Array
  },
  computed: {
    itemStatus() {
      return true;
    }
  },
  methods: {
    equipItem(itemID) {
      this.$root.$data.items[itemID - 1].equipped = true;
      if (this.$root.$data.equipped.indexOf(itemID) == -1) {
        this.$root.$data.equipped.push(itemID);
      }
      document.getElementById("equip" + itemID).style["display"] = "none";
      document.getElementById("unequip" + itemID).style["display"] = "block";
    },
    unequipItem(itemID) {
      this.$root.$data.items[itemID - 1].equipped = false;

      if (this.$root.$data.equipped.indexOf(itemID) != -1) {
        let delId = this.$root.$data.equipped.indexOf(itemID);
        this.$root.$data.equipped.splice(delId, 1);
      }
      document.getElementById("equip" + itemID).style["display"] = "block";
      document.getElementById("unequip" + itemID).style["display"] = "none";
    },
    addItem() {
      let maxID = 0;
      for (var key in this.$root.$data.items) {
        if (this.$root.$data.items.length < 1) {
          maxID = 0;
        }
        else if (this.$root.$data.items[key].id > maxID){
          maxID = this.$root.$data.items[key].id;
        }
      }
      maxID += 1;
      let name = document.getElementById("itemName").value;
      let typeSelector = document.getElementById("itemType");
      let type = typeSelector.options[typeSelector.selectedIndex].value;
      let defSelector = document.getElementById("itemDefense");
      let defense = defSelector.options[defSelector.selectedIndex].value;
      let rarfSelector = document.getElementById("itemRarity");
      let rarity = rarfSelector.options[rarfSelector.selectedIndex].value;

      this.$root.$data.items.push( {
        id: maxID,
        name: name,
        type: type,
        defense: defense,
        rarity: rarity,
        equipped: "false"
      })
    },
    deleteItem(id) {
      for (var key in this.$root.$data.items) {
        if (this.$root.$data.items[key].id == id) {
          this.$root.$data.items.splice(key, 1);
        }
      }
      if (this.$root.$data.equipped.indexOf(id) != -1) {
        let delId = this.$root.$data.equipped.indexOf(id);
        this.$root.$data.equipped.splice(delId, 1);
      }
    }
  }
}
</script>

<style scoped>
.item-list-content {
  width: 100%;
}
.items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  border: 2px solid black;
  background: #627d99;
}
.item-name {
  text-align: center;
  color: white;
  margin: 20px;
}
.item-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px;
  width: 100%;
  background: #F4F1E9;
}
.item-stats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: #f5f5f5;
  border: 1px solid black;
}
p {
  margin: 5px;
}
.item-info-head {
  font-weight: bold;
}
.equip-button, .delete-button {
  text-align: center;
  height: 40px;
  width: 60px;
  margin: 5px 10px;
}
.item-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
}
.fieldset {
  display: flex;
  flex-direction: column;
}
input, select {
  margin-bottom: 10px;
  border: none;
  border-radius: 3px;
  padding: 3px;
}
.hidden {
  display: none;
}
.addItem {
  margin-bottom: 20px;
}
.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttons a {
  margin: 5px 0;
  font-weight: bold;
  color: #eee;
}
</style>
