let mock = [
  {
    id:1,
    name:"Ordinary Helmet",
    type:"Head",
    defense:"1",
    rarity:"1",
    equipped:"false"
  },
  {
    id:2,
    name:"Ordinary Chestplate",
    type:"Chest",
    defense:"5",
    rarity:"1",
    equipped:"false"
  },
  {
    id:3,
    name:"Ordinary Guantlets",
    type:"Arm",
    defense:"2",
    rarity:"1",
    equipped:"false"
  },
  {
    id:4,
    name:"Ordinary Greaves",
    type:"Leg",
    defense:"1",
    rarity:"1",
    equipped:"false"
  },
  {
    id:5,
    name:"Knight Helmet",
    type:"Head",
    defense:"2",
    rarity:"2",
    equipped:"false"
  },
  {
    id:6,
    name:"Special Pendulum",
    type:"Misc",
    defense:"2",
    rarity:"5",
    equipped:"false"
  },
  {
    id:7,
    name:"Hunter's Helmet",
    type:"Head",
    defense:"3",
    rarity:"2",
    equipped:"false"
  },
  {
    id:8,
    name:"Assault Vest",
    type:"Chest",
    defense:"25",
    rarity:"8",
    equipped:"false"
  },
  {
    id:9,
    name:"Normal Boots",
    type:"Leg",
    defense:"1",
    rarity:"10",
    equipped:"false"
  },
  {
    id:10,
    name:"Steel Bracer",
    type:"Arm",
    defense:"6",
    rarity:"4",
    equipped:"false"
  },
]

export default mock;
